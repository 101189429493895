import React, { Component } from 'react';
import Datas from '../../data/event/events.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import CourseSidebar from './components/CourseSidebar';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/event.js';
import axios from 'axios';
import { StylesPagination } from './styles/pagination.js';
import Pagination from './../../components/Pagination_Event';
import moment from 'moment';

const url = 'https://adminkto.stechomeyazilim.info';

class Events extends Component {
     constructor() {

            super();
        this.state = {
            arrayEvent: [],
        ID:'',
        activePage: 1,
eventLenght:0,
PageSize:0,
        };
    }
    

         async componentDidMount() {

        await this._getEvent(null,0,4);
        await this._getEventAll(null);



}




_getEventAll = async (value) => {
     try{
     
     await axios.get(`https://kto.stechomeyazilim.info:5000/eventAll/select/${value}`)
      .then(res => {
        
                    this.setState({
eventLenght: res.data.length        

                  }, () => {

                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}


_getEventSearch = async (value) => {
     try{
       this.setState({
            arrayEvent: [],
        })

     await axios.get(`https://kto.stechomeyazilim.info:5000/event/selectWord/${value}`)
      .then(res => {
        
                    this.setState({
arrayEvent: res.data,
eventLenght:res.data.length
                  }, () => {


                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}

_getEvent = async (value,first,second,pageNumber) => {
     try{
       this.setState({
            arrayEvent: [],
        })

     await axios.get(`https://kto.stechomeyazilim.info:5000/event/select/${value}/${first}/${second}`)
      .then(res => {
        
                    this.setState({
arrayEvent: res.data,

                  }, () => {


                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}

    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-page">

 

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Etkinlikler" />

                    {/* Events Area */}
                    <section className="event-page-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="12">
                                    {
                                        this.state.arrayEvent.map((data, i) => (
                                            <div className="event-box" key={i}>
                                                <Row>
                                                    <Col xl="3" lg="4" md="0">
                                                        <div className="event-img">
                                                            <Link to={{pathname:  "/etkinlik-detay/" + (data.Event_Title).replace(/\s+/g, '-'), state: { ID: data.ID }}}><img src={url + `${data.Event_Image}`} alt="" className="img-fluid" /></Link>
                                                        </div>
                                                    </Col>
                                                    <Col xl="9" lg="8" md="2">
                                                        <div className="event-content">
                                                            <div className="content-box">
                                                                <Row>
                                                                    <Col md="9">
                                                                        <div className="event-title">
                                                                            <h6><Link to={{pathname:  "/etkinlik-detay/" + (data.Event_Title).replace(/\s+/g, '-'), state: { ID: data.ID }}}>{data.Event_Title}</Link></h6>
                                                                        </div>
                                                                        <div className="event-time-location">
                                                                            <ul className="list-unstyled list-inline">
                                                                                <li className="list-inline-item"><i className="las la-map-marker"></i> {data.Event_Location}</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="event-desc">
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="3" className="text-center">
                                                                        <div className="event-date">
                                                                            <p>{moment((data.Event_Date_Start).substring(0,10)).format('DD-MM-YYYY')}</p>
                                                                        </div>
                                                                        <div className="join-btn">
                                                                            <Link  to={{pathname: "/etkinlik-detay/" + (data.Event_Title).replace(/\s+/g, '-'), state: { ID: data.ID }}}>Katıl</Link>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))
                                    }
                                  

                                    <Col md="12" className="text-center">

                                    <Pagination _starting = {this._getEvent} eventLenght = {this.state.eventLenght}/>

                                              
      
                                    </Col>
                                      
                                </Col>

                                <Col lg="3" md="0">
                                    <CourseSidebar _getEventSearch= {this._getEventSearch}/>
                                </Col>

                            </Row>
                        </Container>
                    </section>


                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default Events