import React, { Component } from 'react';
import Datas from '../../data/faq/faq.json';
import { Accordion, Card, Button, Row, Col, Tab, Nav,Table,Container } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/projects.js';
import axios from 'axios';

import {
  FaArrowUp,
  FaArrowDown,
  FaFlag,
  FaBriefcase,
  FaUser,
  FaBuilding,
  FaMoneyBill,
} from "react-icons/fa";



class tamamlananprojelerimiz extends Component {
        constructor() {
        super();
        this.state = {
ArrayProject: [],
ArrayProjectCategory: [],
      buttonState: [{id:""}],
      projectsArray:[],
      selectedValue: {},

        };
    }
    
    async componentDidMount() {

        await this._starting();
console.log("dskadmfslkm")
}
passId(id){
    id=this.state.ID;
  }
_starting = async () => {

          await  this.getProjects();

    
}

  getProjects = async () => {
    try {

let category = "degil";
let order = "degil";

        await axios.get(`https://kto.stechomeyazilim.info:5000/projects/select/${category}/${order}/1/2`)
      .then(res => {

  this.setState({
                projectsArray : res.data

          }, () => {

          })

      })

   
    } catch (error) {
      console.log("error" + error);
    }
  };



_toggleItem = async (itemId,category,order) => {

    const { selectedValue } = this.state;
    const isSelected = selectedValue[itemId];
    selectedValue[itemId] = !isSelected;


await axios.get(`https://kto.stechomeyazilim.info:5000/projects/select/${category}/${order}/0`)
      .then(res => {

  this.setState({
                projectsArray : res.data

          }, () => {

          })

      })


    this.setState({
       selectedValue: {...selectedValue},
      }, () => {


      })

  }

    render() {
         const { selectedValue } = this.state;

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper faq-page">

                    {/* Header 2 */}
                                     <HeaderTwo />


                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Tamamlanan Projeler" />

                    {/* Faq Area */}
                    <section className="faq-area" >
                            <Row style={{margin:'auto',display:'block',width:'85%', paddingTop: 10}}>
                                <Col md="12">
                                 
          <Table  className="responsive_image" bordered style={{ 
backgroundImage: `url(assets/images/ktoo.jpg)`
  //backgroundImage: `url(${imageLink})` 
}} >
            <thead>
              <tr>
                <th style={{ width:'15%',borderColor:'gray',borderWidth:1,textAlign: "center" }}>
                  <FaBriefcase /> <div><a className = "aClass"> Proje Adı</a>
                  <a 
                    onClick={() =>
                     this._toggleItem("0", "Projects_Name", selectedValue["0"] == true ? "azalan" : "artan" )
                    }
                  >
                  </a></div>
                   {selectedValue["0"]? <FaArrowUp style = {{color: 'red'}}/> : <FaArrowDown />}
                </th>
                <th style={{ width:'15%',borderColor:'gray',borderWidth:1,textAlign: "center" }}>
                  <FaBriefcase /> <div><a className = "aClass">Durumu</a>
                   <a
                    onClick={() =>
                     this._toggleItem("1", "ProjectsStatus", selectedValue["1"] == true ? "azalan" : "artan" )
                    }
                  >
                  </a></div>
                                      {selectedValue["1"]? <FaArrowUp style = {{color: 'red'}}/> : <FaArrowDown />}
                </th>
                <th style={{ width:'15%',borderColor:'gray',borderWidth:1,textAlign: "center" }}>
                  <FaFlag />  <div><a className = "aClass">Ulusal/Uluslaraası </a>
                  <a
                    onClick={() =>
                     this._toggleItem("2", "National_Check", selectedValue["2"] == true ? "azalan" : "artan" )
                    }
                  >
                  </a></div>
                                      {selectedValue["2"]? <FaArrowUp style = {{color: 'red'}} /> : <FaArrowDown />}
                </th>
                <th style={{ width:'15%',borderColor:'gray',borderWidth:1,textAlign: "center" }}>
                  <FaUser />  <div><a className = "aClass">Proje Koordinatörü</a>
                 <a
                    onClick={() =>
                     this._toggleItem("3", "Project_Coordinator", selectedValue["3"] == true ? "azalan" : "artan" )
                    }
                  >
                  </a></div>
                                      {selectedValue["3"]? <FaArrowUp style = {{color: 'red'}}/> : <FaArrowDown />}
                </th>
                <th style={{ width:'15%',borderColor:'gray',borderWidth:1,textAlign: "center" }}>
                  <FaBuilding />  <div><a className = "aClass">Proje Yürüten Kuruluş </a>
                   <a
                    onClick={() =>
                     this._toggleItem("4", "Project_Institution", selectedValue["4"] == true ? "azalan" : "artan" )
                    }
                  >
                  </a></div>
                                      {selectedValue["4"]? <FaArrowUp style = {{color: 'red'}}/> : <FaArrowDown />}
                </th>
                <th style={{ width:'15%',borderColor:'gray',borderWidth:1,textAlign: "center" }}>
                  
                  <FaMoneyBill /> <div><a className = "aClass">Proje Fon Sağlayan kuruluşlar </a>
                 <a
                    onClick={() =>
                     this._toggleItem("5", "Project_Budget", selectedValue["5"] == true ? "azalan" : "artan" )
                    }
                  >
                  </a></div>
                                      {selectedValue["5"]? <FaArrowUp style = {{color: 'red'}}/> : <FaArrowDown />}
                </th>
                <th style={{ width:'100%',borderColor:'gray',borderWidth:1,textAlign: "center" }}>
                  <FaMoneyBill />  <div><a className = "aClass">Proje Bütçesi </a>
                 <a
                    onClick={() =>
                     this._toggleItem("6", "Project_Budget", selectedValue["6"] == true ? "azalan" : "artan" )
                    }
                  >
                  </a></div>
                                      {selectedValue["6"]? <FaArrowUp style = {{color: 'red'}}/> : <FaArrowDown />}

                </th>
              </tr>
            </thead>
            {this.state.projectsArray.map((item, i) => (
              <tr key={item.ID}>
                                <th style={{   fontFamily: "Times New Roman", textAlign: "center" }}>
{item.Projects_Name}</th>
               { item.ProjectsStatus == 0 ?                 <th style={{   fontFamily: "Times New Roman", textAlign: "center" }}>
Tamamlandı</th> :
                               <th style={{   fontFamily: "Times New Roman", textAlign: "center" }}>
Devam Ediyor</th>}
                
                <th style={{   fontFamily: "Times New Roman", textAlign: "center" }}>
                  <a onClick={() => this.passId(item.ID)}></a>
                  {item.National_Check}
                </th>
                <th style={{   fontFamily: "Times New Roman", textAlign: "center" }}>
                  {item.Project_Coordinator}
                </th>
                <th style={{   fontFamily: "Times New Roman", textAlign: "center" }}>
                  {item.Project_Institution}
                </th>
                <th style={{   fontFamily: "Times New Roman", textAlign: "center" }}>
                  {item.Project_Budget_Institution}
                </th>
                <th style={{   fontFamily: "Times New Roman", textAlign: "center" }}>
                {item.Project_Budget}</th>
              </tr>
            ))}
          </Table>
                                </Col>
                            </Row>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default tamamlananprojelerimiz