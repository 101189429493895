import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";
import axios from 'axios';

class MobileMenu extends Component {
  constructor() {
        super();
        this.state = {
            arraySlider: [],
            ArrayProjectCategory: [],
            ArrayStudentCategory:[]
        };
    }

      async componentDidMount() {

        await this._starting();

}

_getStudentCategory= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/studentCategory/select')
      .then(res => {

  this.setState({
                ArrayStudentCategory : res.data

          }, () => {

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }

_getProjectsCategory= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/projectsCategory/select/')
      .then(res => {

  this.setState({
                ArrayProjectCategory : res.data

          }, async() => {

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }


_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/getContact/select/')
      .then(res => {
        
         res.data.map((data, i) => ( 

     this.setState({
Adress_Text: data.Adress_Text,
Email_Adress : data.Email_Adress,
Phone_Numer : data.Phone_Numer

                  }, async() => {
                               await this._getProjectsCategory();
                               await this._getProjectsCategory();

                       console.log("Adress_Text", res.data.cikti)
                  })

                         ))

      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}
    
   render() {
     const hmBtn = document.getElementById("mb-sidebar-btn");

        if (hmBtn) {
            const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
            const mdSidebarBody = document.getElementById("mb-sidebar-body");
            const mdSidebarExit = document.getElementById("close-mb-sidebar");

            hmBtn.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.toggle("visible");
                mdSidebarBody.classList.toggle("opened");
            });

            mdSidebarOverlay.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });

            mdSidebarExit.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });
        }

        // Menu Accordion -----------------
        const menuButton = document.querySelectorAll(".mb-menu-button");
        menuButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "mb-menu-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "mb-menu-content";
                    content.style.maxHeight = "0";
                }
            });
        });

     return (
 <Styles>
            {/* Mobile Menu */}
            <section className="mobile-menu-area">
                <Container>
                    <Row>
                        <Col md="0" sm="12">
                            <div className="mb-topbar d-flex justify-content-between">
                                <div className="topbar-item">
                                    <p><i className="las la-phone"></i>{this.state.Phone_Numer}</p>
                                </div>
                                <div className="topbar-item">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/inavasyon"}>Fikir, Proje ve İnovasyon</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="mb-logo-area d-flex justify-content-between">
                                <div className="mb-logo-box d-flex">
                                    <div className="hm-button">
                                        <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                                            <i className="las la-bars"></i>
                                        </a>
                                    </div>
                                    <div className="mb-logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="" /></Link>
                                    </div>
                                </div>
                                <div className="mb-search-box">
                                    <form action="#">
                                        <input type="text" name="search" placeholder="Ara" />
                                        <button type="submit"><i className="las la-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mobile Menu Sidebar */}
            <section className="mb-sidebar" id="mb-sidebar-body">
                <div className="mb-sidebar-heading d-flex justify-content-between">
                    <div><h5>Menu</h5></div>
                    <div><a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar"><i className="las la-times"></i></a></div>
                </div>
                <div className="mb-sidebar-menu">
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Anasayfa <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/"}>Anasayfa</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Hakkımızda <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/ekibimiz"}>Ekibimiz</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/tanitimmateryal"}>Tanıtım Materyalleri</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/tanitimvideo"}>Tanıtım Videosu</Link></li>
                                <li><Link to={process.env.PUBLIC_URL  + "/mevzuat"}>Mevzuat</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/mevzuat"}>Performans</Link></li>

                            </ul>
                        </div>
                    </div>
                    
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Projeler <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">

                                <li><Link to={process.env.PUBLIC_URL + "/devam-eden-bilimsel-projelerimiz"}>Devam Eden Bilimsel Projelerimiz</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/tamamlanan-bilimsel-projelerimiz"}>Tamamlanan Bilimsel Projelerimiz</Link></li>

                            </ul>
                        </div>
                    </div>
                  
                                      <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Yayınlar <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/ekibimiz"}>DSPACE</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/tanitimmateryal"}>Yayın Teşvik Başvurusu</Link></li>
                            </ul>
                        </div>
                    </div>
                  

                                      <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>İletişim <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/iletisim"}>İletişim</Link></li>
                            
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
        </Styles>
      )
    }

}
export default MobileMenu