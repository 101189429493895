import React, { Component } from 'react';
import Datas from '../data/footer/footer2.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerTwo.js";
import axios from 'axios';
import { Timeline } from 'react-twitter-widgets'

class FooterTwo extends Component{
       constructor() {
        super();
        this.state = {          

}
        this.onFormSubmit = this.onFormSubmit.bind(this);

}

  async componentDidMount() {

        await this._starting();

}



_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/getContact/select/')
      .then(res => {
        
         res.data.map((data, i) => ( 

     this.setState({
Adress_Text: data.Adress_Text,
Email_Adress : data.Email_Adress,
Phone_Numer : data.Phone_Numer

                  }, () => {
                       
                       console.log("Adress_Text", res.data.cikti)
                  })

                         ))

      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}


     onFormSubmit = async(e) => {
   
   const form = document.getElementById("form4");
        const email = document.getElementById("email4");


    
            e.preventDefault();

            const emailValue = email.value.trim();

            if (emailValue === "") {
                this.setError(email, "Mail Adresi Boş Geçilemez");
            } else if (!this.isEmail(emailValue)) {
                this.setError(email, "Yanlış Mail Formatı");
            } else {
                this.setSuccess(email);
            }


  const data = {
     Subscribe_Mail: emailValue,
  };


   axios({
            method: "POST",
            url: "https://kto.stechomeyazilim.info:5000/subscribe/insert",
            data: ({
        Subscribe_Mail:  emailValue,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {

  this.setState({isLoading:false});

         alert("Başarıyla Abonelik İşleminiz Gerçekleştirilmiştir");

          });

        
    }

  

         setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".input-msg2");
            formControl.className = "form-control error";
            errorMsg.innerText = message;
        }

         setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

         isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }

render() {

    return (
        <Styles>
            {/* Footer Two */}
            <footer className="footer2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})` }}>
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="footer-logo-info">
                                <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="" className="img-fluid" />
                                <p>KTO Karatay Üniversitesi</p>
                                <ul className="list-unstyled">
                                    <li><i className="las la-map-marker"></i>{this.state.Adress_Text}</li>
                                    <li><i className="las la-envelope"></i>{this.state.Email_Adress}</li>
                                    <li><i className="las la-phone"></i>{this.state.Phone_Numer}</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="f-links">
                                <h5>Yararlı Linkler</h5>
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Tamamlanan Projeler</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Devam Eden Projeler</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Etkinlikler</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Gizlilik Politakamız</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="3">
                            <div className="f-post">
                                <h5>Twitter Post</h5>
                                 <Timeline
  dataSource={{
    sourceType: 'profile',
    screenName: 'karataytto'
  }}
 options={{ theme: "dark", width: "250", height: "300" }}
/>
                                </div>
                        </Col>
                        <Col md="3">
                            <div className="f-newsletter">
                                <h5>Abonelik</h5>
                                <p>Son Gelişmelerden haberdar olmak için abone olmak ister misiniz?</p>

                                <form id="form4" className="form">
                                    <p className="form-control">
                                        <input type="email" placeholder="Mail Adresiniz" id="email4" />
                                        <span className="input-msg4"></span>
                                    </p>
                                    <button>Abone Ol</button>
                                </form>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="copytext-area text-center">
                                    <p>Copyright &copy; 2020 | Designed With <i className="las la-laptop-code"></i> by <a href={"https://stechome.com.tr"} target="_blank" rel="noopener noreferrer">Stechome</a></p>
                            
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Back To Top  */}
                <BackToTop />
            </footer>
        </Styles>
    );
} }

export default FooterTwo
