import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles1 = styled.div`
.accordion .card {
    margin-bottom: 0;
}
.accordion .card {
  background-color: #f8f8f8;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
}
.accordion .card-header {
  padding: 10;
  border-bottom: 0;
  width: 100%;
  flex-basis: 100%;
  order: 1;
}
.accordion .card .collapseparent{
  order: 2;
}
.accordion .card .card-header .btn {
  position: relative;
  background-color: #f8f8f8;
  border-bottom: 0;
  padding: .75rem 1.25rem;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #313131;
  font-weight: 600;
  width: 100%;
  border-radius: 0;
  text-decoration: none;
}
.accordion .card .card-header .btn:focus{
  box-shadow: none;
}
.accordion .card .collapseparent.show+.card-header .btn,
.accordion .card-header .btn[aria-expanded="true"] {
    background-color: #0C376F;
    color: #fff;
}

.accordion .card-header .btn::after {
    content: '';
    position: absolute;
    top: 30%;
    margin-top: -3px;
    right: 0.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
    z-index: 1;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.accordion .card-header .btn::before {
    content: '';
    position: absolute;
    top: 30%;
    margin-top: -3px;
    right: 0.25rem;
    width: 10px;
    height: 2px;
    background-color: #848486;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 1;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    z-index: 1;
    box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}
.accordion .card .collapseparent.show+.card-header .btn::before,
.accordion .card-header .btn[aria-expanded="true"]::before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 0;
}
.accordion .card .collapseparent.show+.card-header .btn::after,
.accordion .card .collapseparent.show+.card-header .btn::before,
.accordion .card-header .btn[aria-expanded="true"]::after,
.accordion .card-header .btn[aria-expanded="true"]::before {
    background-color: #0C376F;
}

.accordion .card-header i {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
    font-size: 20px;
}
.accordion .card .collapseparent.show+.card-header .btn i,
.accordion .card-header .btn[aria-expanded="true"] i {
    color: #0C376F;
}

.accordion .card {
    border: #f8f8f8;
}

.accordion.with-gap .card-header {
  border-radius: 0;
}

.accordion.with-gap .card {
    margin-bottom: 10px;
}
.accordion .card::before{
  content: '';
  position: absolute;
  top: 8px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: block;
  z-index: 1;
}
.accordion.with-gap .card:last-child {
    margin-bottom: 0;
}

.accordion.with-gap .card:first-of-type,
.accordion.with-gap .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid #efefef;
}


`;