import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/teamSlider.js";
import axios from 'axios';
import {Helmet} from "react-helmet";
import { FP } from "./css/demo.js";
import './css/jquery.flipster.min.css';
import { Link } from 'react-router-dom';

import * as $ from 'jquery';
import moment from 'moment';

const url = 'https://adminkto.stechomeyazilim.info';


class TeamSlider extends Component {

             constructor() {

            super();
        this.state = {
            arrayEvent: [],
            currentDate:"",
        };
    }
    


     async componentDidMount() {


  this.setState({
currentDate: moment().format('YYYY-MM-D')
                  }, async() => {
                               await this._starting();

                  })




}


_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/event/select/null/0/9')
      .then(res => {
        
                    this.setState({
arrayEvent: res.data
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }
}

    render() {
        const settings = {
            slidesPerView: 4,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 4
                }
            }
        };

        return (
            <Styles>








                {/* Team Slider */}
                <section className="team-member-area">
                  
                </section>
            </Styles>
        )
    }
}

export default TeamSlider
