import React, { Component } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import base64 from 'react-native-base64'
import ReactHtml from 'raw-html-react';

import Datas from '../../data/event/events.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from '../../components/Pagination';
import CourseSidebar from './components/CourseSidebar';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/event.js';
import axios from 'axios';
import { StylesPagination } from './styles/pagination.js';

import { Styles1 } from './styles/faqs.js';


import DocViewer from "react-doc-viewer";

const urlLink = 'https://kto.stechomeyazilim.info';

const docs = [
    { uri: "https://kto.stechomeyazilim.info/public/uploads/IMAGE-1620211117210.pdf" },
 // Local File
  ];

class Project extends Component {
    constructor() {
        super();
        this.state = {
            ArraySSS: [],
          docs:[],

        };
    }
    
    async componentDidMount() {


await this._starting('bos');
}


_searchProject= async (value,value1) => {
       this.setState({ ArraySSS: [],
       docs : [] })

     try {      

await axios.get(`https://kto.stechomeyazilim.info:5000/project/search/${value}/${value1}`)
      .then(res => {

  this.setState({
                ArraySSS : res.data
          }, () => {



          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }

}


_starting = async (value) => {
       this.setState({ ArraySSS: [],
       docs : [] })

     try {      
let value1 = null

if(this.props.location.state ==null)
{ 
    await axios.get(`https://kto.stechomeyazilim.info:5000/student/select/1/${value}`)
      .then(res => {

  this.setState({
                ArraySSS : res.data
          }, () => {

                 
     res.data.map((eventData, i) => (
       this.setState({ docs: [...this.state.docs,{ uri : urlLink + "/" + eventData.Study_Pdf_Link}] })
                                            ))     

          })

      })
  }
  if(this.props.location.state.ID !=null){
 await axios.get(`https://kto.stechomeyazilim.info:5000/student/select/${this.props.location.state.ID}/${value}`)
      .then(res => {

  this.setState({
                ArraySSS : res.data
          }, () => {

                 
     res.data.map((eventData, i) => (
       this.setState({ docs: [...this.state.docs,{ uri : urlLink + "/" + eventData.Study_Pdf_Link}] })
                                            ))     

          })

      })
  }

              

        } catch (error) {
          
          console.log("error" + error)
        }

}


    render() {
        return (
           <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Öğrenci Projeleri" />

                    {/* Events Area */}
                    <section className="event-page-area">
                        <Container>
                            <Row>
                                <Col lg="9" md="12">
                                                                               <Styles1>

                                    <div className="section">

                <div className="container">
                    <div className="row">

                        {this.state.ArraySSS.map((item, i) => (
                                                <div className="col-lg-6">

                                                    <Accordion defaultActiveKey="0" className="with-gap" >

                                        <Card >
                                        <Accordion.Collapse  eventKey= {i == 0 || i == 1 ? "0" : "1"} className="collapseparent">
                                            <Card.Body>


 <iframe
        src={"https://docs.google.com/viewer?url=" + urlLink + "/" + item.Study_Pdf_Link + "&embedded=true"}
        title="file"
        width="100%"
        height="320"
      ></iframe>


                                                </Card.Body>
                                        </Accordion.Collapse>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey= {i == 0 || i == 1 ? "0" : "1"} >
                                            {item.Study_Name_Surname}

                                            
                                                                                            </Accordion.Toggle>
                                        </Card.Header>
                                    </Card> 
                                    </Accordion>
                                    </div>

                                    ))}


         
              
                    </div>
                </div >
            </div >
                                  
</Styles1>
                                  
                                      
                                </Col>

                                <Col lg="3" md="0">
                                    <CourseSidebar _category_ID = {this.props.location.state != null ? this.props.location.state.ID : 1} _searchProject={this._searchProject}/>
                                </Col>

                            </Row>
                        </Container>
                    </section>


                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>


           
        );
    }
}

export default Project;