import React, { Component } from 'react';
import Datas from '../../data/instructor/instructor.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from './../../components/Pagination_Ekip';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/instructor.js';
import axios from 'axios';

const url="https://adminkto.stechomeyazilim.info"
class Instructor extends Component {
         constructor() {

            super();
        this.state = {
            arrayTeams: [],
        arrayLenght:0
        };
    }
    


     async componentDidMount() {

        await this._starting(0,9);
await this._startingSum();
}




_starting = async (value,value2) => {
       this.setState({
            arrayTeams: [],
        })
       
     try{
     
     await axios.get(`https://kto.stechomeyazilim.info:5000/teams/select/${value}/${value2}`)
      .then(res => {
        
                    this.setState({
arrayTeams: res.data
                  }, () => {
                       
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }
}

_startingSum = async () => {
     try{
     let value = null;
    let value2 = null;

     await axios.get(`https://kto.stechomeyazilim.info:5000/teams/select/`)
      .then(res => {
        
                    this.setState({
arrayLenght: res.data.length
                  }, () => {
                  })
      })

    }
    catch (error) {
      
      console.log("error" + error)
    }
}
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Ekibimiz" />

                    {/* Instructor Area */}
                    <section className="instructor-area">
                        <Container>
                            <Row>
                                {
                                    this.state.arrayTeams.map((data, i) => (
                                        <Col lg="3" md="4" sm="6" key={i}>
                                            <div className="instructor-item">
                                                <Link ><img src={url + `${data.Empleyees_Image}`} alt="" className="img-fluid" /></Link>
                                                <div className="img-content text-center">
                                                    <h5><Link>{data.Empleyees_NameSurname}</Link></h5>
                                                    <p>{data.Employees_Tasks}</p>
                                                   <ul className="social list-unstyled list-inline">
                                            <li className="list-inline-item"><a href={"https://twitter.com/" + "/"}><i className="fab fa-twitter"></i></a></li>
                                            <li className="list-inline-item"><a href={"https://linkedin.com/" + "/"}><i style = {{color:'blue'}}className="fab fa-linkedin-in"></i></a></li>
                                                                          </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }

                      
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default Instructor