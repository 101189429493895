import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/contact.js';
import axios from 'axios';
import {Helmet} from "react-helmet";
import notify from 'devextreme/ui/notify';

import './styles/selectstyle.css';

        const form = document.getElementById("form_contact");
        const name = document.getElementById("Name");


class ProjectUpload extends Component {
            constructor() {
        super();
        this.state = {          
            file: null,
            studentCategory:[],
            selectedCategoryID:1,
            studentDepartmant:[],
            selectedDepartmantID:0,
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async componentDidMount() {
        await this._starting();
        await this._getstudentCategory();
        await this._getstudentDepartmant();
}


_starting = async () => {

     try{
     
     await axios.get('https://kto.stechomeyazilim.info:5000/getContact/select/')
      .then(res => {
        
         res.data.map((data, i) => ( 

     this.setState({
Adress_Text: data.Adress_Text,
Email_Adress : data.Email_Adress,
Phone_Numer : data.Phone_Numer

                  }, () => {
                       
                       console.log("Adress_Text", res.data.cikti)
                  })

                         ))

      })

    }
    catch (error) {
      
      console.log("error" + error)
    }


}

_getstudentCategory= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/studentCategory/select/')
      .then(res => {

  this.setState({
                studentCategory : res.data

          }, () => {

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }


_getstudentDepartmant= async () => {
        try {      
    

await axios.get('https://kto.stechomeyazilim.info:5000/departmants/select/')
      .then(res => {

  this.setState({
                studentDepartmant : res.data

          }, () => {

          })

      })
  
              

        } catch (error) {
          
          console.log("error" + error)
        }
      }
      


_pstData = async (value,Name) => {

const name = document.getElementById("Name");


  const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
      const data = {
     Study_Pdf_Link: value, // This is the body part
     Study_Name_Surname: Name,
     Study_Project_Category_ID: this.state.selectedCategoryID,
     Universt_Departments_ID: this.state.selectedDepartmantID,

  };
             const form = document.getElementById("form_contact");


fetch("https://kto.stechomeyazilim.info:5000/studentProject/insert", {
  method: "post",
  body: JSON.stringify(data),
  headers: { "Content-Type": "application/json" }
})
.then(res =>  this.showToast('KTO TTO', "Projeniz başarıyla yüklenmiştir, onaylandıktan sonra yayınlanacaktır", 'success'))
.catch(err => console.log(err))

}
   
   showToast(event, value, type) {
                 const form = document.getElementById("form_contact");

    notify(`${event} "${value}"`, type, 4000);
    form.reset();
  }

           onFormSubmit = async(e) => {

            e.preventDefault();

  if (this.state.file == null){
this.showToast('KTO TTO', "Lütfen Yükleyeceğiniz Fotoğrafı Seçiniz", 'error')
  }

    if (this.state.selectedDepartmantID == 0){
this.showToast('KTO TTO', "Lütfen Yükleyeceğiniz Fotoğrafı Seçiniz", 'error')
  }

    if (this.state.selectedDepartmantID != 0){

    if (this.state.file != null){

             const form = document.getElementById("form_contact");
        const Study_Academic_Year1 = document.getElementById("Study_Academic_Year");
        const Name1 = document.getElementById("Name");

          e.preventDefault();
          var Name = this.Name.value.trim();



            if (Name === "") {
                this.setError(Name1, "İsim Alanı Boş Geçilmez");
            } else {
                this.setSuccess(Name1);
            }
        

        const formData = new FormData();
        formData.append('file',this.state.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

       
        axios.post("https://kto.stechomeyazilim.info:5000/upload",formData,config)
            .then(async(response) => {
            this.setState({file:null});

           await this._pstData(response.data,Name)
            }).catch((error) => {
        }); 
  }
        }
    }
    onChange(e) {
        this.setState({file:e.target.files[0]});
    }


    

        formSubmit = async(e) => {


              const form = document.getElementById("form_contact");
        const Name1 = document.getElementById("Name");
       
        const Study_Academic_Year1 = document.getElementById("Study_Academic_Year");

          e.preventDefault();
    var Name = this.Name.value.trim();
    var Study_Academic_Year = this.Study_Academic_Year.value.trim();



            if (Name === "") {
                this.setError(Name1, "İsim Alanı Boş Geçilmez");
            } else {
                this.setSuccess(Name1);
            }


            if (Study_Academic_Year === "") {
               this.setError(Study_Academic_Year1, "Akademik Alan Boş Geçilmez");
            } else {
                this.setSuccess(Study_Academic_Year1);
            }

         
        }

         setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".contact_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }

         setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

         isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }




  handleDepartmant = (event) => {
    this.setState({ selectedDepartmantID: event.target.value });
  }

  handleChangeCATEGORY = (event) => {
    this.setState({ selectedCategoryID: event.target.value });
  }

   render() {
    return (
        <Styles>



            {/* Main Wrapper */}
            <div className="main-wrapper contact-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Proje Yükleme" />

                {/* Contact Area */}
                <section className="contact-area">

               

                    <Container>

 
                        <Row>
                            <Col md="4">
                                <div className="contact-box-title">
                                    <h4>İletişim Bilgileri</h4>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-map-marker"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Konum</h5>
                                        <p>{this.state.Adress_Text}</p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-envelope-open"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Mail Adresi</h5>
                                        <p>{this.state.Email_Adress}<br /></p>
                                    </div>
                                </div>
                                <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-phone"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>İletişim Numarası</h5>
                                        <p>{this.state.Phone_Numer}<br /></p>
                                    </div>
                                </div>
                             
                            </Col>
                            <Col md="8">
                                <div className="contact-form">
                                    <div className="form-title">
                                        <h4>Proje Yükleme</h4>
                                    </div>
                                    <div className="form-box">
                                        <form id="form_contact" className="form">
                                            <Row>
                                                <Col md="6">
                                                    <p className="form-control">
                                                        <input  ref={(c) => this.Name = c} type="text" placeholder="Adınız Soyisiminiz" id="Name" />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
                                                </Col>
                                                <Col md="6">                                                 <div className="box">
  <select onChange={this.handleDepartmant} placeholder="Bölümünüzü Seçiniz">
     { this.state.studentDepartmant.map((data, i) => ( 
                                                  <option value={data.ID} >{data.Departments_Name}</option>                         ))
                                                                                                     }

  </select>
</div>



                                                  
                                                </Col>

                                              
                                               
                                               <Col md="12">
                        
                        <p className="form-control">
                <input  className="form-control"  type="file" name="file" onChange= {this.onChange} />
                                                        <span className="contact_input-msg"></span>
                                                    </p>
</Col>
                  

   <Col md="12">

                                                 <div className="box">
  <select onChange={this.handleChangeCATEGORY} placeholder="Akademik Yılı Seçiniz">
     { this.state.studentCategory.map((data, i) => ( 
                                                  <option value={data.ID} >{data.Project_Category_Name}</option>                         ))
                                                                                                     }

  </select>
</div>


                                                </Col>

 
                                             

                                                
                                                <Col md="12">
                                                    <button style = {{marginTop:25}} onClick={this.onFormSubmit}>Formu Gönder</button>
                                                </Col>


                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    {/* Google Map */}
                 
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}}

export default ProjectUpload